import React, { useEffect, useState } from "react";
import { Container, Button, Row, Col, Carousel } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import Images from "./images";
import { sociallistApi } from "./API/cms.api";


function Footer() {
    const [social, SetSocial ] = useState(null);

    useEffect(()=>{
        fetchSocial()

    },[])

    const fetchSocial = async() => {
        const socail = await sociallistApi()
        console.log("🚀 ~ fetchSocial ~ socail:", socail)
        if(socail.status) SetSocial(socail.data);
        
    }
    return (

        <>
            <img src={Images.footertopimg} className="img-fluid footertopimg mt-4 w-100" />
            <Row className="align-items-center text-center text-lg-start pb-3">
            <Col md={12} lg={3} xl={3} xxl={3} className="mt-3 mt-lg-0">
            <img src={Images.logo} className="img-fluid logos footlogo" />
            </Col> 
            <Col md={12} lg={5} xl={5} xxl={4} className="mt-3 mt-lg-0">
            <p className="text-white copyright mb-0">Copyright @ 2024 Galfi. All Rights Reserved</p>
            </Col> 
            <Col md={12} lg={4} xl={4} xxl={5} className="socialmedia justify-content-center justify-content-lg-end pe-4 mt-3 mt-lg-0">
             <a href={social?.medium} target="_blank"> <img src={Images.social1} className="img-fluid mediumicon socialicon" /></a>
             <a href={social?.discord}  target="_blank"> <img src={Images.social2} className="img-fluid socialicon" /></a>
             <a href={social?.telegram}  target="_blank"> <img src={Images.social3} className="img-fluid socialicon" /></a>
             <a href={social?.twitter}  target="_blank"> <img src={Images.social4} className="img-fluid twiticon socialicon" /></a>
            </Col> 
            </Row>
        </>

    )
}

export default Footer;