const Envname = "demo";
let key = {};


key.NumDigitOnly = /[^0-9\.]/g
key.NumberOnly = /[^0-9]/g
key.CDN =  "https://dmywb5hxgl1qh.cloudfront.net/"

if (Envname === "local") {
    key.COMMON_URL = 'http://localhost:4000/v1/common'
    key.BACK = 'http://localhost:4000/'

    key.BACK_V1 = 'http://localhost:4000/v1'
    key.BACK_URL = 'http://localhost:4000/v1/front'
    key.AdminAPI = 'http://localhost:4000/v1/admin'
    key.CMS = 'http://localhost:4000/v1/cms'
    key.EXCHANGE = 'http://localhost:4000/v1/exchange'

    key.IMG_URL = 'https://backend-galfi.maticz.in'
}

if (Envname === "demo") {
    key.BACK_V1 = 'https://backend-galfi.maticz.in/v1'
    key.COMMON_URL = 'https://backend-galfi.maticz.in/v1/common'
    key.BACK_URL ='https://backend-galfi.maticz.in/v1'
    key.AdminAPI = 'https://backend-galfi.maticz.in/v1/admin'
    key.CMS = 'https://backend-galfi.maticz.in/v1/cms'
    key.EXCHANGE = 'https://backend-galfi.maticz.in/v1/exchange'
    key.IMG_URL =  'https://backend-galfi.maticz.in'

}

export default key