import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Landing from "./Landing";
import Blog from "./blog";
import Blogdetail from "./blogdetail";
import News from "./news";
import Newsdetail from "./newsdetail";

function App() {
  return (
    <div className="App">
           <BrowserRouter>
           <Routes>
             <Route path="/" element={<Landing />} />
             <Route path="/blog/:id" element={<Blogdetail />} />

             <Route path="/blogs" element={<Blog />} />
             <Route path="/news" element={<News />} />
             <Route path="/news/:id" element={<Newsdetail />} />
           </Routes> 
           </BrowserRouter>
    </div>
  );
}

export default App;
