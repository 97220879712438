const Images = {
    logo : require("./Assets/logo.png"),
    astronaut : require("./Assets/astronaut.png"),
    leftarrowimg : require("./Assets/leftarrow.png"),
    rightarrowimg : require("./Assets/rightarrow.png"),
    cardimg: require("./Assets/cardimg.png"),


    partner1 : require("./Assets/partner1.png"),
    partner2 : require("./Assets/partner2.png"),

    footertopimg : require("./Assets/footerborder.png"),
    circle : require("./Assets/circle.png"),
    social1 : require("./Assets/social1.png"),
    social2 : require("./Assets/social2.png"),
    social3 : require("./Assets/social3.png"),
    social4 : require("./Assets/social4.png"),
    discard:require("./Assets/discard.png"),
    tele:require("./Assets/tele.png"),
    twit:require("./Assets/twit.png"),
    dot:require("./Assets/dot.png"),
    shield : require("./Assets/shield.svg").default,
    shelter : require("./Assets/shelter.svg").default,
    shelterhover : require("./Assets/shelterhover.svg").default,
    rocket : require("./Assets/rocket.svg").default,
    rockethover : require("./Assets/rockethover.svg").default,
    target : require("./Assets/target.svg").default,
    targethover : require("./Assets/targethover.svg").default,
    layer : require("./Assets/layer.svg").default,
    layerhover : require("./Assets/layerhover.svg").default,
    transact : require("./Assets/transact.svg").default,
    transacthover : require("./Assets/transacthover.svg").default,
    flag : require("./Assets/flag.svg").default,
    flaghover : require("./Assets/flaghover.svg").default,
    galaxy : require("./Assets/galaxy.svg").default,
    galaxyhover : require("./Assets/galaxyhover.svg").default,
    group : require("./Assets/group.svg").default,
    grouphover : require("./Assets/grouphover.svg").default,
    cloudChakra : require("./Assets/cloud_chakra.png"),
}

export default Images
